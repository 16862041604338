import './index.css';

import { H } from 'highlight.run';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { ErrorBoundary } from '@highlight-run/react';

import { App } from './App';
import { reportWebVitals } from './reportWebVitals';
import { FragmentEnv } from './utils/env';

// NOTE: This is a workaround for SyntaxHighlighter not working reliably in the
// EmbedCodeDialog component. It seems to have the effect of warming some
// sort of internal cache that avoids a race condition within
// SyntaxHighlighter.
// See: https://github.com/react-syntax-highlighter/react-syntax-highlighter/issues/513
createRoot(document.createElement('div')).render(
  // eslint-disable-next-line react/no-children-prop
  <SyntaxHighlighter language="" children="" />
);

if (FragmentEnv.highlight.projectId && !window.Cypress) {
  const hResult = H.init(FragmentEnv.highlight.projectId, {
    backendUrl: `https://${FragmentEnv.highlight.domain}`,
    privacySetting: 'strict',
    tracingOrigins: true,
    reportConsoleErrors: true,
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      requestResponseSanitizer: (pair) => {
        const result = {
          request: { ...pair.request, body: '[REMOVED]' },
          response: { ...pair.response, body: '[REMOVED]' },
          urlBlocked: pair.urlBlocked,
        };
        return result;
      },
    },
  });
  if (hResult) {
    window.highlightSessionId = hResult.sessionSecureID;
  }
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// @ts-expect-error "we import restify-errors which assumes process.env exists"
window.process = {
  env: {},
};
